import React from 'react'

export default function Loader() {
    return (
        <div class="preloader">
            <div class="preloader-inner">
                <div class="loading-window">
                    <div class="car">
                        <div class="strike"></div>
                        <div class="strike strike2"></div>
                        <div class="strike strike3"></div>
                        <div class="strike strike4"></div>
                        <div class="strike strike5"></div>
                        <img style={{ height: "40px" }} src="assets/img/logo-iffts-white-font.png" alt="Logo" />
                        <div class="strike strike2"></div>
                        <div class="strike strike3"></div>
                        <div class="strike strike4"></div>
                        <div class="strike strike5"></div>

                    </div>
                </div>
            </div>
        </div>
    )
}
