// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBexX51y-S3COIUzbkBNsoEMFv6TfERxFY",
    authDomain: "iffts-4e7a0.firebaseapp.com",
    databaseURL: "https://iffts-4e7a0-default-rtdb.firebaseio.com",
    projectId: "iffts-4e7a0",
    storageBucket: "iffts-4e7a0.appspot.com",
    messagingSenderId: "169833283170",
    appId: "1:169833283170:web:37f58c9662fdb97411db3b",
    measurementId: "G-XZSVCEDCQ1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();
const auth = getAuth(app)
export { db, auth };