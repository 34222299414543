import { collection, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../utils/Firebase";
import Loader from "../components/Loader";

export default function AdminDashboard() {
  const user = window.sessionStorage.getItem("userTk")
  const navigate = useNavigate()

  const [tab, setTab] = useState(1)
  const [enquiries, setEnquiries] = useState([])
  const [bookings, setBookings] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const getEnquiries = async () => {
    try {
      const collectionRef = query(collection(db, 'enquiries'), orderBy('date', 'desc'));
      const data = await getDocs(collectionRef);
      let arrEnquiries = []
      data.forEach((doc) => {
        arrEnquiries.push({ ...doc.data(), id: doc.id })
      });
      arrEnquiries = arrEnquiries.map(enq => ({ ...enq, date: enq.date.toDate() }))
      setEnquiries(arrEnquiries)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  const getBookings = async () => {
    try {
      const collectionRef = query(collection(db, 'bookings'), orderBy("bookingDate", "desc"))
      const data = await getDocs(collectionRef);
      let arrBookings = []
      data.forEach((doc) => {
        arrBookings.push({ ...doc.data(), id: doc.id })
      });
      arrBookings = arrBookings.map(book => ({ ...book, bookingDate: book.bookingDate.toDate() }))
      setBookings(arrBookings)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }

  useEffect(() => {
    if (!user) {
      navigate("/admin")
    }

    getEnquiries()
    getBookings()
    // const script = document.createElement("script");
    // script.src = "assets/js/main.js";
    // script.async = true;
    // document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // This adds smooth scrolling animation (optional)
  });

  return (
    <>
      {isLoading && <Loader />}
      <section class="th-blog-wrapper blog-details space-top space-extra-bottom">
        <div class="container mt-5">
          <div class="title-area text-center">
            <h2 class="sec-title"> IFFTS Admin Dashbaord</h2>
          </div>
          <div class="col-lg-12 ps-lg-2 mt-5">
              <aside class="sidebar-area">
                <div class="widget widget_categories">
                  <h4 class="widget_title">Dashbaord</h4>
                  <ul>
                    <li>
                      <Link to="#" onClick={() => setTab(1)}>Bookings</Link>
                      <span>({bookings.length})</span>
                    </li>
                    <li>
                      <Link to="#" onClick={() => setTab(2)}>Enqiries</Link>
                      <span>({enquiries.length})</span>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
          <div class="row flex-row-reverse">
            <div class="col-lg-12 " style={{ overflowX: "scroll" }}>
              {tab === 2 ?
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Email</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Message</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiries.map((enq, ind) => {
                      return (
                        <tr>
                          <td>{ind + 1}</td>
                          <td>{enq.name}</td>
                          <td>{enq.phoneNumber}</td>
                          <td>{enq.email}</td>
                          <td>{enq.subject}</td>
                          <td>{enq.message}</td>
                          <td>{`${new Date(enq.date).getDate()}/${new Date(enq.date).getMonth() + 1}/${new Date(enq.date).getFullYear()}`}</td>
                        </tr>
                      )
                    })}
                    {/* 
                <tr>
                    <th scope="row">2</th>
                    <td>Jane Smith</td>
                    <td>janesmith@example.com</td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>Bob Johnson</td>
                    <td>bob@example.com</td>
                </tr> */}
                  </tbody>
                </table>
                :
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">Email</th>
                      <th scope="col">Start Destination</th>
                      <th scope="col">End Destination</th>
                      <th scope="col">Pickup Date</th>
                      <th scope="col">Pickup Time</th>
                      <th scope="col">Car</th>
                      <th scope="col">Booking Date</th>
                      <th scope="col">Package</th>
                      <th scope="col">Trip</th>
                      <th scope="col">Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.map((booking, ind) => {
                      return (
                        <tr>
                          <td>{ind + 1}</td>
                          <td>{booking.name}</td>
                          <td>{booking.phoneNumber}</td>
                          <td>{booking.email}</td>
                          <td>{booking.startDestination}</td>
                          <td>{booking.endDestination}</td>
                          <td>{`${new Date(booking.pickupDate).toLocaleDateString('en-US', { day: 'numeric' })}/${new Date(booking.pickupDate).toLocaleDateString('en-US', { month: 'numeric' })}/${new Date(booking.pickupDate).toLocaleDateString('en-US', { year: 'numeric' })}`}</td>
                          <td>{booking.pickupTime}</td>
                          <td>{booking.car}</td>
                          <td>{`${new Date(booking.bookingDate).getDate()}/${new Date(booking.bookingDate).getMonth() + 1}/${new Date(booking.bookingDate).getFullYear()}`}</td>
                          <td>{booking.packages}</td>
                          <td>{booking.tripType}</td>
                          <td>{booking.message}</td>
                        </tr>
                      )
                    })}
                    {/* <tr>
                    <th scope="row">1</th>
                    <td>John Doe</td>
                    <td>johndoe@example.com</td>
                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td>Jane Smith</td>
                    <td>janesmith@example.com</td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>Bob Johnson</td>
                    <td>bob@example.com</td>
                </tr> */}
                  </tbody>
                </table>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
