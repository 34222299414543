import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import cars from "../data/cars.json"

export default function Service() {
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/main.js";
    script.async = true;
    document.body.appendChild(script);
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This adds smooth scrolling animation (optional)
    });
    return () => {
      document.body.removeChild(script);
    };
  }, [location.pathname]);

  const sendWhatsApp = (id) => {
    const pack = cars.find(c => c.id === id)
    if (pack) {
      // var title = `Hi!
      // I want to book ${pack.cars.map((c, i) => (`${c.carName} ${pack.cars.length > i + 1 ? '/ ' : ''}`))} ,
      // Please contact me on this number`;
      var phoneNumber = '+919844834400';
      var message = encodeURIComponent(
        `Hi!
I want to book ${pack.cars.map((c, i) => (`${c.carName} `))}
Please contact me on this number
      `);  // Pre-filled message
      var whatsappLink = 'https://wa.me/' + phoneNumber + '?text=' + message;
      window.open(whatsappLink, '_blank');
    }
  }

  return (
    <>
      <div
        class="breadcumb-wrapper"
        data-bg-src="assets/img/breadcumb/breadcumb-bg.jpg"
        data-overlay="title"
        data-opacity="4"
      >
        <div class="container z-index-common">
          <h1 class="breadcumb-title">Service</h1>
          <ul class="breadcumb-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Service</li>
          </ul>
        </div>
      </div>
      <section class="space">
        <div class="container">
        <div class="title-area mb-30">
          <h4 class="sec-title text-center">Book outstation Cabs</h4>
            <h4 class="text-center" style={{color:"#4744fa"}}>Available Cabs</h4>
          </div>
          <div class="taxi-tab filter-menu-active">
            <button data-filter="*" class="th-btn active" type="button">
              ALL TAXI
            </button>
            <button data-filter=".hatchBack" class="th-btn" type="button">
              HATCH BACK CARS
            </button>
            <button data-filter=".sedan" class="th-btn" type="button">
              SEDAN CARS
            </button>
            <button data-filter=".suv" class="th-btn" type="button">
              SUV CARS
            </button>
            <button data-filter=".miniBus" class="th-btn" type="button">
              MINI BUS
            </button>
          </div>
          <div class="row gy-30 filter-active justify-content-center">
            {cars.map(car => {
              return (
                <div class={`col-xl-4 col-md-6 filter-item ${car.category}`}>
                  <div class="taxi-box">
                    <div class="taxi-box_img">
                      <div class="slider">
                        {car.cars.map(c => (
                          <div class="image-slide">
                            <img src={c.url} alt={c.carName} />
                          </div>
                        ))}
                      </div>
                    </div>
                    <h4 class="taxi-box_title" style={{ fontSize: "13px" }}>
                      {car.cars.map((c, i) => (`${c.carName} ${car.cars.length > i + 1 ? '/ ' : ''}`))}
                    </h4>
                    <h4 class="taxi-box_rate">₹ {car.outStationPrice}/km</h4>
                    <div class="taxi-feature">
                      <div class="taxi-feature_icon">
                        <img src="assets/img/icon/taxi_f_1_2.svg" alt="png" />
                      </div>
                      <h3 class="taxi-feature_title">Passengers:</h3>
                      <span class="taxi-feature_info">{car.passengers}</span>
                    </div>
                    <div class="taxi-feature">
                      <div class="taxi-feature_icon">
                        <img src="assets/img/icon/taxi_f_1_5.svg" alt="png" />
                      </div>
                      <h3 class="taxi-feature_title">Air Condition:</h3>
                      <span class="taxi-feature_info">{car.ACType}</span>
                    </div>
                    <div>
                      <Link to="/Booking" state={{ id: car.id, packType : 3 }} class="th-btn">
                        Book This Car Now
                      </Link>
                      <Link to="#"
                        class="th-btn th-btn-whatsapp"
                        onClick={() => sendWhatsApp(car.id)}
                      >
                        <img src="assets/img/icon/whatsapplogo.svg" alt="whatsapplogo" />   whatsapp

                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </>
  );
}
