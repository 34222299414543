import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom'
import { db } from "../utils/Firebase";
import { collection, addDoc } from "firebase/firestore"
import Swal from "sweetalert2";
import Loader from "../components/Loader";

export default function ContactUs() {
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'assets/js/main.js'
    script.async = true
    document.body.appendChild(script);
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This adds smooth scrolling animation (optional)
    });
    return () => {
      document.body.removeChild(script)
    }
  }, [location.pathname])

  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: ""
  })

  const collectionRef = collection(db, 'enquiries')


  function isValidEmail(email) {
    // Regular expression pattern for a basic email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
  }

  function isValidPhoneNumber(phoneNumber) {
    // Regular expression pattern for a 10-digit phone number with only digits
    const phoneRegex = /^\d{10}$/;

    // Test the phone number against the regular expression
    return phoneRegex.test(phoneNumber);
  }


  const validateFields = () => {
    if (userDetails.name.trim() === "") {
      return "Please enter your name.";
    }

    if (userDetails.email.trim() === "" || !isValidEmail(userDetails.email)) {
      return "Please enter a valid email address.";
    }

    if (userDetails.phoneNumber.trim() === "" || !isValidPhoneNumber(userDetails.phoneNumber)) {
      return "Please enter a valid 10 digit phone number.";
    }

    if (userDetails.subject.trim() === "") {
      return "Please enter subject.";
    }

    if (userDetails.message.trim() === "") {
      return "Please enter message.";
    }

    // All fields are valid
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (validateFields() != null) {
        Toast.fire({
          icon: 'error',
          title: validateFields()
        })
        return false
      }

      setIsLoading(true)
      userDetails.date = new Date()
      const data = await addDoc(collectionRef, userDetails)
      //console.log(data);
      setUserDetails({
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: ""
      })
      setIsLoading(false)
      Swal.fire({
        titleText: "Thank you for contacting us!",
        text: "We will contact you soon",
        icon: "success"
      })
    } catch (e) {
      console.log(e);
      setIsLoading(false)
    }
  }
  return (
    <>
      {isLoading && <Loader />}
      <div
        class="breadcumb-wrapper"
        data-bg-src="assets/img/breadcumb/breadcumb-bg.jpg"
        data-overlay="title"
        data-opacity="4"
      >
        <div class="container z-index-common">
          <h1 class="breadcumb-title">Contact Us</h1>
          <ul class="breadcumb-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Contact Us</li>
          </ul>
        </div>
      </div>
      <section class="space">
        <div class="container">
          <div class="title-area mb-30">
            <h3 class="sec-title text-center">Contact Now For Taxi Booking</h3>
          </div>

          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="nav-one"
              role="tabpanel"
              aria-labelledby="nav-one-tab"
            >
              <div class="row gy-30 justify-content-center">
                <div class="col-md-6 col-lg-4">
                  <div class="contact-box style2">
                    <div class="contact-box_content">
                      <div class="contact-box_icon">
                        <i class="fa-light fa-map-location-dot"></i>
                      </div>
                      <div class="contact-box_info">
                        <p class="contact-box_text">OUR OFFICE LOCATION</p>
                        <h6 class="contact-box_link" style={{ fontSize: "12px" }}>
                          No. 427/2. 1st Floor, RightWing, 4th Main Rd, HMT Layout, Anandnagar, Bengaluru, Karnataka 560024
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="contact-box style2">
                    <div class="contact-box_content" style={{ height: "250px" }}>
                      <div class="contact-box_icon">
                        <i class="fa-light fa-phone-arrow-up-right"></i>
                      </div>
                      <div class="contact-box_info">
                        <p class="contact-box_text">Have A Question?</p>
                        <h5 class="contact-box_link">
                          <a href="tel:+919844834400">+91 9844834400</a>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="contact-box style2">
                    <div class="contact-box_content" style={{ height: "250px" }}>
                      <div class="contact-box_icon">
                        <i class="fa-light fa-envelopes-bulk"></i>
                      </div>
                      <div class="contact-box_info">
                        <p class="contact-box_text">Any question? email us!</p>
                        <h5 class="contact-box_link">
                          <a href="mailto:info@iffts.com">info@iffts.com</a>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-four"
              role="tabpanel"
              aria-labelledby="nav-four-tab"
            >
            </div>
          </div>
        </div>
      </section>
      <section class="space-bottom position-relative">
        <div class="container">
          <div class="contact-form-wrapper">
            <div class="col-lg-12">
              <form
                onSubmit={handleSubmit}
                class="contact-form ajax-contact"
              >
                <div class="title-area mb-30 text-center text-lg-start">
                  <h2 class="sec-title text-center">
                    You Can <span class="text-theme">Connect</span> With US
                  </h2>
                  <p class="mb-30 text-center">
                    Let us know, How can we help you in your journey............!
                  </p>
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      id="name"
                      onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })}
                      value={userDetails.name}
                      placeholder="Enter Your Name"
                      required
                    />
                    <i class="fal fa-user"></i>
                  </div>
                  <div class="form-group col-md-6">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                      value={userDetails.email}
                      placeholder="Email Address"
                      required
                    />
                    <i class="fal fa-envelope"></i>
                  </div>
                  <div class="form-group col-md-6">
                    <input
                      type="number"
                      class="form-control"
                      name="phone"
                      id="phone"
                      placeholder="Phone Number"
                      onChange={(e) => setUserDetails({ ...userDetails, phoneNumber: e.target.value })}
                      value={userDetails.phoneNumber}
                      required
                    />
                    <i class="fa-light fa-phone"></i>
                  </div>
                  <div class="form-group col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      name="Subject"
                      id="subject"
                      placeholder="Subject"
                      onChange={(e) => setUserDetails({ ...userDetails, subject: e.target.value })}
                      value={userDetails.subject}
                      required
                    />
                    <i class="fal fa-comment"></i>
                  </div>
                  <div class="form-group col-12">
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="3"
                      class="form-control"
                      placeholder="Message"
                      onChange={(e) => setUserDetails({ ...userDetails, message: e.target.value })}
                      value={userDetails.message}
                      required
                    ></textarea>
                    <i class="fal fa-comment"></i>
                  </div>
                  <div class="form-btn col-12 text-center">
                    <button class="th-btn fw-btn">
                      Send Message<i class="fa-regular fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
                <p class="form-messages mb-0 mt-3"></p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}