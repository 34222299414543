import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
export default function Error() {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'assets/js/main.js'
    script.async = true
    document.body.appendChild(script);
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // This adds smooth scrolling animation (optional)
      });
    return () => {
        document.body.removeChild(script)
    }
}, [])
  return (
    <>
     <div
      class="breadcumb-wrapper"
      data-bg-src="assets/img/breadcumb/breadcumb-bg.jpg"
    >
      <div class="container z-index-common">
        <h1 class="breadcumb-title">Error Page</h1>
        <ul class="breadcumb-menu">
          <li><Link to="/">Home</Link></li>
          <li>Error Page</li>
        </ul>
      </div>
    </div>
    <section class="space">
      <div class="container">
        <div class="error-img">
          <img src="assets/img/icon/Error.jpg" alt="404 image" />
        </div>
        <div class="error-content">
          <h2 class="error-title">
            <span class="text-theme">OooPs!</span> Page Not Found
          </h2>
          <p class="error-text">
            Oops! The page you are looking for does not exist. It might have
            been moved or deleted.
          </p>
          <div class="btn-group justify-content-center">
          <Link to="/">
            <a  class="th-btn"
              ><i class="fal fa-home me-2"></i>Back To Home</a
            ></Link>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}
