import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cars from "../../data/cars.json";

export default function PackageService() {
  const location = useLocation();
  const { type } = location.state;

  const [packDuration, setPackDuration] = useState("1");
  const [airportTripType, setAirportTripType] = useState("1");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/main.js";
    script.async = true;
    document.body.appendChild(script);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This adds smooth scrolling animation (optional)
    });
    return () => {
      document.body.removeChild(script);
    };
  }, [location.pathname]);

  const sendWhatsApp = (id) => {
    const pack = cars.find((c) => c.id === id);
    if (pack) {
      // var title = `Hi!
      // I want to book ${pack.cars.map((c, i) => (`${c.carName} ${pack.cars.length > i + 1 ? '/ ' : ''}`))} ,
      // Please contact me on this number`;
      var phoneNumber = "+919844834400";
      var message = encodeURIComponent(
        `Hi!
I want to book ${pack.cars.map((c, i) => `${c.carName} `)}
${type == 1 ? "Airport taxi" : "Local taxi for "}
Please contact me on this number
      `
      ); // Pre-filled message
      var whatsappLink = "https://wa.me/" + phoneNumber + "?text=" + message;
      window.open(whatsappLink, "_blank");
    }
  };

  const getPrice = (car) => {
    if (type == 1) {
      return (
        <>
          <h4 class="taxi-box_rate">₹ {car.airportPrice} + Toll</h4>
          <h6 style={{ fontSize: "15px", color: "red" }}>30 KM Limit</h6>
        </>
      );
    } else if (type == 2) {
      return (
        <h4 class="taxi-box_rate">
          ₹ {car.localPrice[packDuration]}
          <p style={{ fontSize: "13px" }}>
            {packDuration == 1 && "4 hrs/40 kms"}{" "}
            {packDuration == 2 && "8 hrs/80 kms"}{" "}
            {packDuration == 3 && "12 hrs/120 kms"}
          </p>
        </h4>
      );
    }
  };

  return (
    <>
      <div
        class="breadcumb-wrapper"
        data-bg-src="assets/img/breadcumb/breadcumb-bg.jpg"
        data-overlay="title"
        data-opacity="4"
      >
        <div class="container z-index-common">
          <h1 class="breadcumb-title">
            {type == 1 ? "Airport Taxi" : "Local Taxi"}
          </h1>
          <ul class="breadcumb-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Packages</li>
          </ul>
        </div>
      </div>
      <section class="space">
        <div class="container">
          <div class="title-area mb-30">
            <h4 class="sec-title text-center">
              Book {type == 1 ? "Airport" : "Local"} Cab
            </h4>
            <h4 class="text-center" style={{ color: "#4744fa" }}>
              Available Cabs
            </h4>
          </div>
          {type == 1 && (
            <div class="taxi-tab filter-menu-active">
              <button
                class={`th-btn ${airportTripType == "1" && "active"}`}
                type="button"
                onClick={() => setAirportTripType("1")}
              >
                Airport to city
              </button>
              <button
                class={`th-btn ${airportTripType == "2" && "active"}`}
                type="button"
                onClick={() => setAirportTripType("2")}
              >
                City to airport
              </button>
              {/* <button class="th-btn" type="button" onClick={()=>setPackDuration("12 Hr 120 km")}>
                Round trip
              </button> */}
            </div>
          )}
          {type == 2 && (
            <div class="taxi-tab filter-menu-active">
              <button
                class={`th-btn ${packDuration == "1" && "active"}`}
                type="button"
                onClick={() => setPackDuration("1")}
              >
                4hrs/40 KM
              </button>
              <button
                class={`th-btn ${packDuration == "2" && "active"}`}
                type="button"
                onClick={() => setPackDuration("2")}
              >
                8hrs/80 KM
              </button>
              <button
                class={`th-btn ${packDuration == "3" && "active"}`}
                type="button"
                onClick={() => setPackDuration("3")}
              >
                12hrs/120 KM
              </button>
            </div>
          )}
          <div class="taxi-tab filter-menu-active">
            <button data-filter="*" class="th-btn active" type="button">
              ALL CARS
            </button>
            <button data-filter=".hatchBack" class="th-btn" type="button">
              HATCH BACK CARS
            </button>
            <button data-filter=".sedan" class="th-btn" type="button">
              SEDAN CARS
            </button>
            <button data-filter=".suv" class="th-btn" type="button">
              SUV CARS
            </button>
            <button data-filter=".miniBus" class="th-btn" type="button">
              MINI BUS
            </button>
          </div>
          <div class="row gy-30 filter-active justify-content-center">
            {cars.map((car) => {
              return (
                <div class={`col-xl-4 col-md-6 filter-item ${car.category}`}>
                  <div class="taxi-box">
                    <div class="taxi-box_img">
                      <div class="slider">
                        {car.cars.map((c) => (
                          <div class="image-slide">
                            <img src={c.url} alt={c.carName} />
                          </div>
                        ))}
                      </div>
                    </div>
                    <h4 class="taxi-box_title" style={{ fontSize: "15px" }}>
                      {car.cars.map(
                        (c, i) =>
                          `${c.carName} ${car.cars.length > i + 1 ? "/ " : ""}`
                      )}
                    </h4>
                    {getPrice(car)}
                    <div class="taxi-feature">
                      <div class="taxi-feature_icon">
                        <img src="assets/img/icon/taxi_f_1_2.svg" alt="png" />
                      </div>
                      <h3 class="taxi-feature_title">Passengers:</h3>
                      <span class="taxi-feature_info">{car.passengers}</span>
                    </div>
                    <div class="taxi-feature">
                      <div class="taxi-feature_icon">
                        <img src="assets/img/icon/taxi_f_1_5.svg" alt="png" />
                      </div>
                      <h3 class="taxi-feature_title">Air Condition:</h3>
                      <span class="taxi-feature_info">{car.ACType}</span>
                    </div>
                    <div>
                      <Link
                        to="/Booking"
                        state={{
                          id: car.id,
                          packType: type,
                          tripType: type == 2 ? packDuration : airportTripType,
                        }}
                        class="th-btn"
                      >
                        Book This Car Now
                      </Link>
                      <a
                        class="th-btn th-btn-whatsapp"
                        onClick={() => sendWhatsApp(car.id)}
                      >
                        <img
                          src="assets/img/icon/whatsapplogo.svg"
                          alt="whatsapplogo"
                        />{" "}
                        whatsapp
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
