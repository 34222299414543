import React, { useEffect } from "react";
import { Link } from 'react-router-dom'

export default function Footer() {
  // useEffect(() => {
  //   const script = document.createElement('script')
  //   script.src = 'assets/js/main.js'
  //   script.async = true
  //   document.body.appendChild(script);

  //   return () => {
  //       document.body.removeChild(script)
  //   }
  // }, [])
  return (
    <>
    <div class="scroll-top">
      <svg
        class="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path
          d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          style={{
            transition: "stroke-dashoffset 10ms linear 0s",
            strokeDasharray: "307.919, 307.919",
            strokeDashoffset: "307.919"}}
          
        ></path>
      </svg>
    </div> 
<footer
      class="footer-wrapper footer-layout9 background-image"
      style={{backgroundColor: "#1b1b1b"}}
    >
      <hr class="style1" />
      <div class="widget-area">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-md-6 col-xl-auto">
              <div class="widget footer-widget">
                <h3 class="widget_title">About IFFTS</h3>
                <div class="th-widget-about">
                  <p class="footer-text">
                  Since our establishment in 2012, we have consistently strived for excellence in every
                   aspect of our service. Our fleet of modern, well-maintained vehicles ensures a safe and comfortable
                    ride for all our passengers.
                  </p>
                  <h4 class="footer-info-title mb-15">WE ARE AVAILABLE</h4>
                  <p class="footer-text">Mon-Sun: 24 hours </p>
                </div>
                <div class="th-social footer-social">
                  <a target="_blank" href="https://www.facebook.com/profile.php?id=100078918618369&mibextid=avESrC"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a target="_blank" href="https://twitter.com/"
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a target="_blank" href="https://instagram.com/ifftscabs?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D"
                    ><i class="fab fa-instagram"></i
                  ></a>
                  <a target="_blank" href="https://www.linkedin.com/in/iffts-cabs-7727371b9"
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                  <a href="https://www.youtube.com/@ifftsCabsCarsAndSolutions">
                  <i class="fab fa-youtube"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-2">
              <div class="widget widget_nav_menu footer-widget ">
                <h3 class="widget_title">Quick link</h3>
                <div class="menu-all-pages-container">
                  <ul class="menu">
                    <li>
                    <Link to="/AboutUs">
                      About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/Service">Service</Link>
                    </li>
                    <li>
                    <Link to="/ContactUs">ContactUs</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="widget footer-widget">
                <h3 class="widget_title">Contact Details</h3>
                <div class="th-widget-about">
                  <h4 class="footer-info-title">Phone Number</h4>
                  <p class="footer-info">
                  <i class="fa-solid fa-phone"></i>
                    <a class="text-inherit" href="tel:+919844834400"
                      >+91 9844834400</a
                    >
                  </p>
                  <h4 class="footer-info-title">Email Address</h4>
                  <p class="footer-info">

                    <i class="fas fa-envelope"></i><a class="text-inherit" href="mailto:info@iffts.com"
                      >info@iffts.com</a
                    >
                  </p>
                  <h4 class="footer-info-title">Office Location</h4>
                  <p class="footer-info">
                    <i class="fas fa-map-marker-alt"></i>No. 427/2. 1st Floor, RightWing, 4th Main Rd, HMT Layout, Anandnagar, Bengaluru, Karnataka 560024
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-wrap style2">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <p class="copyright-text">
                <i class="fa-regular fa-copyright"></i> 2023
                <a href=""> IFFTS.</a> All
                Rights Reserved.
              </p>
            </div>
            <div class="col-lg-6">
              <div class="footer-links">
                <ul>
                  <li>
                  <Link to="/">
                      Home
                      </Link>
                  </li>
                  <li>
                    <a
                      href="#Terms"
                      >Terms</a
                    >
                  </li>
                  <li>
                    <a
                      href="#Terms"
                      >Privacy Policy</a
                    >
                  </li>
                  <li>
                  <Link to="/ContactUs">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-shape"></div>
      </div>
    </footer>
    <div class="phoneicon-sticky">
       <a href="tel:+919844834400">
        <img src="assets/img/icon/phoneicon.png" alt="phoneicon"/>
    </a>
</div>
<div class="whatsapp-sticky">
    <a href="https://api.whatsapp.com/send?phone=919844834400&text=Hello%2C%20I%20would%20like%20to%20book%20a%20taxi" target="_blank">
        <img src="assets/img/icon/whatsapp-logo.png" alt="WhatsApp"/>
    </a>
</div>

    </>
    )
}