import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cars from "../data/cars.json";
import { addDoc, collection } from "firebase/firestore";
import Swal from "sweetalert2";
import { db } from "../utils/Firebase";
import Loader from "../components/Loader";

export default function Booking() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const collectionRef = collection(db, "bookings");
  const today = new Date();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const [selectedCar, setSelectedCar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetils] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    startDestination: "",
    endDestination: "",
    pickupDate: "",
    pickupTime: "",
    car: "",
    carId: "",
    message: "",
  });

  useEffect(() => {
    if (data) {
      const car = cars.find((c) => c.id === data.id);
      if (car) {
        setSelectedCar(car);
        const initDetails = {
          ...details,
          carId: car.id,
        };
        if (data.packType == 1) {
          initDetails.startDestination =
            data.tripType == 1
              ? "Kempegowda International Airport, Bangalore"
              : "";
          initDetails.endDestination =
            data.tripType == 2
              ? "Kempegowda International Airport, Bangalore"
              : "";
        }
        setDetils(initDetails);
      }
    }
    const script = document.createElement("script");
    script.src = "assets/js/main.js";
    script.async = true;
    document.body.appendChild(script);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This adds smooth scrolling animation (optional)
    });
    return () => {
      document.body.removeChild(script);
    };
  }, [location.pathname]);

  const handleInput = (e) => {
    const { name, value } = e.target;

    setDetils({ ...details, [name]: value });
  };

  const handleDateChange = (e) => {
    
    const selectedDate = e.target.value;

    // Convert the selected date string to a Date object
    const selectedDateObj = new Date(selectedDate);

    // Setting hours to 0
    selectedDateObj.setHours(0,0,0,0)
    today.setHours(0,0,0,0)

    // Check if the selected date is not in the past
    if (selectedDateObj >= today) {
      setDetils({ ...details, pickupDate: selectedDate });
    } else {
      // Optionally, you can provide feedback to the user (e.g., show an error message)
      setDetils({ ...details, pickupDate: "" });
      Toast.fire({
        icon: "error",
        title: "Can not select past date please select future date",
      });
    }
  };

  const handleTimeChange = (e) => {
    if (details.pickupDate === "") {
      Toast.fire({
        icon: "error",
        title: "Please select pickup date first",
      });
      return;
    }
    const selectedTime = e.target.value;
    const selectedDateObj = new Date(details.pickupDate);
    
    if (
      selectedDateObj.toISOString().split("T")[0] ===
        today.toISOString().split("T")[0] &&
      selectedTime <= today
    ) {
      Toast.fire({
        icon: "error",
        title: "You can not select current or past time",
      });
      return;
    }

    setDetils({ ...details, pickupTime: selectedTime });
  };

  const handleCarSelect = (e) => {
    const { name, value } = e.target;
    const car = cars.find((c) => c.id == value);
    if (car) {
      setSelectedCar(car);
      setDetils({ ...details, carId: car.id });
    } else {
      setSelectedCar(null);
      setDetils({ ...details, carId: "" });
    }
  };

  function isValidEmail(email) {
    // Regular expression pattern for a basic email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
  }

  function isValidPhoneNumber(phoneNumber) {
    // Regular expression pattern for a 10-digit phone number with only digits
    const phoneRegex = /^\d{10}$/;

    // Test the phone number against the regular expression
    return phoneRegex.test(phoneNumber);
  }

  const validateFields = () => {
    if (details.name.trim() === "") {
      return "Please enter your name.";
    }

    if (details.email.trim() === "" || !isValidEmail(details.email)) {
      return "Please enter a valid email address.";
    }

    if (
      details.phoneNumber.trim() === "" ||
      !isValidPhoneNumber(details.phoneNumber)
    ) {
      return "Please enter a valid 10 digit phone number.";
    }

    if (details.startDestination.trim() === "") {
      return "Please enter the starting destination.";
    }

    if (details.endDestination.trim() === "") {
      return "Please enter the end destination.";
    }

    if (details.pickupDate.trim() === "") {
      return "Please enter pickup date.";
    }

    if (details.pickupTime.trim() === "") {
      return "Please enter the pickup time.";
    }

    if (details.carId === "") {
      return "Please select car.";
    }

    // All fields are valid
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateFields() != null) {
        Toast.fire({
          icon: "error",
          title: validateFields(),
        });
        return false;
      }
      setIsLoading(true);
      details.bookingDate = new Date();
      const car = cars.find((c) => c.id == details.carId);
      details.car = car.cars.map((c) => c.carName).join("/ ");
      if (data.packType == 1) {
        details.packages = "Airport taxi";
        details.tripType =
          data.tripType == 1 ? "Airport to city" : "City to airport";
      } else if (data.packType == 2) {
        details.packages = "Local taxi";
        details.tripType =
          data.tripType == 1
            ? "4 hrs/40 kms"
            : data.tripType == 2
            ? "8 hrs/80 kms"
            : "12 hrs/120 kms";
      } else {
        details.packages = "Outstation";
      }
      const dataSubmitted = await addDoc(collectionRef, details);
      //console.log(data);
      fetch("https://iffts-mail-server.vercel.app/api/send-email", {
        method: "POST",
        body: JSON.stringify(details),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setIsLoading(false);
        });
      setIsLoading(false);
      Swal.fire({
        titleText: "Thank you for booking with us!",
        text: "We will contact you soon! Have a happy journey ahead",
        // icon: "success"
        imageUrl: "assets/img/IFFTS_Color.png",
        imageWidth: 300,
        imageHeight: 100,
        imageAlt: "image",
      }).then(() => {
        navigate("/");
      });
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const getPrice = (car) => {
    if (data.packType == 1) {
      return (
        <h4 class="taxi-box_rate">
          ₹ {car.airportPrice} + Toll
          <p style={{ fontSize: "13px" }}>
            {data.tripType == 1 ? "Airport to city" : "City to airport"}
            <h1 style={{ fontSize: "15px", color: "red" }}>
              Condition Applied :
            </h1>
            *Additional charges at the toll plaza apply.<br></br>
            *This bill is only updated for a limited distance of 30 kilometers.<br></br>
            *Exclusive billing for Airport to City Drop has been included in this invoice.<br></br>
            *Separate billing is applicable for Airport to Outstation trips.<br></br>
            *This bill is specifically updated for Airport Pickup.<br></br>
            *No waiting time is included on the way.<br></br>
          </p>
        </h4>
      );
    } else if (data.packType == 2) {
      return (
        <h4 class="taxi-box_rate">
          ₹ {car.localPrice[data.tripType]}
          <p style={{ fontSize: "13px", marginBottom: 0 }}>
            {data.tripType == 1 && "4 hrs/40 kms"}{" "}
            {data.tripType == 2 && "8 hrs/80 kms"}{" "}
            {data.tripType == 3 && "12 hrs/120 kms"}
          </p>
          <p style={{ fontSize: "13px" }}>
            <h1 style={{ fontSize: "15px", color: "red" }}>
              Condition Applied :
            </h1>
            *Extra hours ₹{selectedCar.cars[0].extraHourCharges}/hr | Extra km ₹
            {selectedCar.cars[0].extraKMCharges}/km
            {<p style={{ fontSize: "13px" }}>
              *we only extent 2 hours in selected package if you want to extent
              more then 2 hours we will calculate as {data.tripType == 3 ? "extra 4 hrs" : data.tripType == 1 ? "8 hrs": "12 hrs"} package
            </p>}
          </p>
        </h4>
      );
    } else {
      return <h4 class="taxi-box_rate">₹ {car.outStationPrice}/Km </h4>;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div
        class="breadcumb-wrapper"
        data-bg-src="assets/img/breadcumb/breadcumb-bg.jpg"
        data-overlay="title"
        data-opacity="4"
      >
        <div class="container z-index-common">
          <h1 class="breadcumb-title">Book Your Taxi</h1>
          <ul class="breadcumb-menu">
            <li>Home</li>
            <li>Booking</li>
          </ul>
        </div>
      </div>

      <div class="position-relative space pt-3">
        <div class="container">
          <div className="col-md-12">
            <a>
              <img
                style={{ height: "100px" }}
                src="assets/img/IFFTS_Color.png"
                alt=""
              />
            </a>
            <h4 class="booking-title">Make Your Booking Now</h4>
          </div>
          <form onSubmit={handleSubmit}>
            <div class="row">
              <div className="col-lg-4">
                <div class="booking-title-area">
                  {selectedCar != null ? (
                    <>
                      <div>
                        {selectedCar.cars.map((c, i) => (
                          <img
                            key={i}
                            src={c.url}
                            alt={c.carName}
                            width="100"
                          />
                        ))}
                      </div>
                      <h4 class="taxi-box_title" style={{ fontSize: "13px" }}>
                        {selectedCar.cars.map(
                          (c, i) =>
                            `${c.carName} ${
                              selectedCar.cars.length > i + 1 ? "/ " : ""
                            }`
                        )}{" "}
                        ({selectedCar.passengers})
                        {data.packType == 1 && " + Limited luggage"}
                      </h4>
                      {getPrice(selectedCar)}
                      {data.packType == 3 && (
                        <p>
                          300 Kms minimum/day | Driver Allowance{" "}
                          {selectedCar.cars[0].driverAllowance}/day
                        </p>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {/* <p class="booking-desc">
                    Booking a taxi with IFFTS is a breeze. Whether you're heading to the airport, a business meeting, or a night out on the town, we've got you covered. Our user-friendly online booking system allows you to quickly enter your pickup location, destination, date, and time.
                  </p> */}
                </div>
              </div>
              <div className="col-lg-8">
                <div class="row">
                  <div class="col-sm-6">
                    <label> Your Name</label>
                    <div class="form-group ">
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        id="name"
                        placeholder="Your Name"
                        onChange={handleInput}
                        value={details.name}
                        required
                      />
                      <i class="fal fa-user"></i>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label> Phone Number</label>
                    <div class="form-group ">
                      <input
                        type="number"
                        class="form-control"
                        name="phoneNumber"
                        id="number"
                        placeholder="Phone Number"
                        onChange={handleInput}
                        value={details.phoneNumber}
                        required
                      />
                      <i class="fal fa-phone"></i>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label> Email id</label>
                    <div class="form-group ">
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="number"
                        placeholder="Email"
                        onChange={handleInput}
                        value={details.email}
                        required
                      />
                      <i class="fal fa-email"></i>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label> Select Car</label>
                    <div class="form-group ">
                      <select
                        class="form-select"
                        name="carId"
                        id="vehicle"
                        onChange={handleCarSelect}
                        value={details.carId}
                        required
                      >
                        <option value="">Select Car</option>
                        {cars.map((car) => {
                          return (
                            <option value={car.id}>
                              {car.cars.map(
                                (c, i) =>
                                  `${c.carName} ${
                                    car.cars.length > i + 1 ? "/ " : ""
                                  }`
                              )}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label> Enter Start Destination</label>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        name="startDestination"
                        id="s-destination"
                        placeholder="Start Destination"
                        onChange={handleInput}
                        value={details.startDestination}
                        required
                        disabled={
                          data.packType == 1 && data.tripType == 1
                            ? true
                            : false
                        }
                      />
                      <i class="fal fa-location-dot"></i>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label> Enter End Destination</label>
                    <div class="form-group ">
                      <input
                        type="text"
                        class="form-control"
                        name="endDestination"
                        id="e-destination"
                        placeholder="End Destination"
                        onChange={handleInput}
                        value={details.endDestination}
                        required
                        disabled={
                          data.packType == 1 && data.tripType == 2
                            ? true
                            : false
                        }
                      />
                      <i class="fal fa-location-dot"></i>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label> Select Pickup Date</label>
                    <div class="form-group">
                      <input
                        type="date"
                        class="form-control"
                        name="pickupDate"
                        id="date-pick"
                        placeholder="Select Pickup Date"
                        onChange={handleDateChange}
                        value={details.pickupDate}
                        min={new Date().toISOString().split("T")[0]}
                        required
                      />
                      {/* <i class="fal fa-calendar-days"></i> */}
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label> Select Pickup Time</label>
                    <div class="form-group">
                      <input
                        type="time"
                        class="form-control"
                        name="pickupTime"
                        id="time-pick"
                        placeholder="Select Pickup Time"
                        onChange={handleTimeChange}
                        value={details.pickupTime}
                        required
                      />
                      {/* <i class="fal fa-clock"></i> */}
                    </div>
                  </div>
                  <div class="col-12">
                    <label> Mention If Any Queries Needed</label>
                    <div class="form-group ">
                      <textarea
                        placeholder="Write a Message...."
                        onChange={handleInput}
                        value={details.message}
                        name="message"
                        id="message"
                        class="form-control"
                      ></textarea>
                      <i class="fa-sharp fa-light fa-pencil"></i>
                    </div>
                  </div>
                  <div class="form-btn col-12">
                    <button class="th-btn fw-btn">
                      Book Taxi Now <i class="fa-regular fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p class="form-messages mb-0 mt-3"></p>
          </form>
        </div>
      </div>
    </>
  );
}
