import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Admin() {
  const auth = getAuth();
  const navigate = useNavigate()
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: ""
  })

  const loginAdmin = (e) => {
    e.preventDefault()
    signInWithEmailAndPassword(auth, userDetails.email, userDetails.password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        if (user) {
          window.sessionStorage.setItem("userTk", user.accessToken)
          navigate("/AdminDashboard")
        }
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Incorrect Email or Password',
        })
        // ..
      });
  }

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "assets/js/main.js";
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  return (
    <>
      <div class="space-top space-extra-bottom">
        <div class="container">
          <div class="row container d-flex justify-content-center align-items-center vh-100">
            <div class="col-lg-6 ">
              <div class="sidebar-area faq-sidebar">
                <div class="widget widget_quote">
                  <h1 class="widget_title">Admin</h1>
                  <form onSubmit={loginAdmin}>
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        name="Admin id"
                        placeholder="Enter your Admin id"
                        required
                        value={userDetails.email}
                        onChange={(e) => setUserDetails({ ...userDetails, email: e.target.value })}
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control"
                        name="Password"
                        id=""
                        placeholder="Enter your Password"
                        required
                        value={userDetails.password}
                        onChange={(e) => setUserDetails({ ...userDetails, password: e.target.value })}
                      />
                    </div>
                    <div class="form-btn">
                      <button class="th-btn w-100" type="submit">
                        Login <i class="fa-regular fa-arrow-right"></i>
                      </button>
                    </div>
                    <p class="form-messages mb-0 mt-3"></p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
