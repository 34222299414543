import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [visible, setVisible] = useState(false);

  //   useEffect(() => {
  //     const script = document.createElement('script')
  //     script.src = 'assets/js/main.js'
  //     script.async = true
  //     document.body.appendChild(script);

  //     return () => {
  //         document.body.removeChild(script)
  //     }
  // }, [])
  const toggleMenu = () => {
    setVisible(!visible);
  };
  return (
    <>
      {/* <div class="preloader">
        <button class="th-btn style3 preloaderCls">Cancel Preloader</button>
        <div class="preloader-inner">
          <div class="loading-window">
            <div class="car">
              <div class="strike"></div>
              <div class="strike strike2"></div>
              <div class="strike strike3"></div>
              <div class="strike strike4"></div>
              <div class="strike strike5"></div>
              <img
                style={{ height: "40px" }}
                src="assets/img/logo-iffts-white-font.png"
                alt="Logo"
              />
              <div class="strike strike2"></div>
              <div class="strike strike3"></div>
              <div class="strike strike4"></div>
              <div class="strike strike5"></div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        class={`th-menu-wrapper ${visible ? "th-body-visible" : ""}`}
        onClick={toggleMenu}
      >
        <div class="th-menu-area text-center">
          <button class="th-menu-toggle" onClick={toggleMenu}>
            <i class="fal fa-times"></i>
          </button>
          <div class="mobile-logo" style={{ backgroundColor: "black" }}>
            <a>
              <img
                style={{ height: "70px" }}
                src="assets/img/logo-iffts-white-font.png"
                alt="Logo"
              />
            </a>
          </div>
          <div class="th-mobile-menu">
            <ul>
              <li class="">
                <Link to="/">Home</Link>
              </li>
              <li>
                {" "}
                <Link to="/AboutUs">About us</Link>
              </li>
              <li class="menu-item-has-children">
                <Link to="/Packages">Packages</Link>
                <ul class="sub-menu">
                  <li>
                    <Link to="/Package" state={{ type: 1 }}>
                      Airport Packages
                    </Link>
                  </li>
                  <li>
                    <Link to="/Package" state={{ type: 2 }}>
                      Local City Packages
                    </Link>
                  </li>
                  <li>
                    <Link to="/Service">Outstation Packages</Link>
                  </li>
                  <li>
                    <Link to="/ContactUs">Corporate Company Boookings</Link>
                  </li>
                  <li>
                    <Link to="/ContactUs">All Events Bookings</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/ContactUs">ContactUs</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <header class="th-header header-layout9 header-absolute">
        <div class="header-top">
          <div class="container">
            <div class="row justify-content-center justify-content-lg-between align-items-center gy-2">
              <div class="col-auto">
                <div class="header-links">
                  <ul>
                    <li>
                      <i class="fa-thin fa-envelope"></i>
                      <a href="mailto:info@iffts.com">info@iffts.com</a>
                    </li>
                    <li>
                      <i class="fa-thin fa-phone"></i>
                      <a href="tel:+919844834400">+91 9844834400</a>
                    </li>
                    <li class="d-none d-xxl-inline-block">
                      <i class="fa-thin fa-map-location-dot"></i>
                      HMT Layout, Anandnagar, Bengaluru- 560024 (INDIA)
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-auto">
                <div class="header-social">
                  <span class="social-title">Follow Us On:</span>
                  <a href="https://www.facebook.com/profile.php?id=100078918618369&mibextid=avESrC">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                  <a href="https://www.twitter.com/">
                    <i class="fab fa-twitter"></i>
                  </a>
                  <a href="https://www.linkedin.com/in/iffts-cabs-7727371b9">
                    <i class="fab fa-linkedin-in"></i>
                  </a>
                  <a href="https://instagram.com/ifftscabs?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D">
                    <i class="fab fa-instagram"></i>
                  </a>
                  <a href="https://www.youtube.com/@ifftsCabsCarsAndSolutions">
                    <i class="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sticky-wrapper">
          <div class="menu-area">
            <div class="container">
              <div class="row justify-content-between align-items-center">
                <div class="col-auto">
                  <div class="header-logo">
                    <Link to="/">
                      <a>
                        <img
                          style={{ height: "52px" }}
                          src="assets/img/logo-iffts-white-font.png"
                          alt="Logo"
                        />
                      </a>
                    </Link>
                  </div>
                </div>
                <div class="col-auto ms-xl-auto">
                  <nav class="main-menu d-none d-lg-block">
                    <ul>
                      <li class="">
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/AboutUs">About us</Link>
                      </li>
                      <li class="menu-item-has-children">
                        <Link to="/Packages">Packages</Link>
                        <ul class="sub-menu">
                          <li>
                            {" "}
                            <Link to="/Package" state={{ type: 1 }}>
                              Airport Packages
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="/Package" state={{ type: 2 }}>
                              Local City Packages
                            </Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="/Service">Outstation Packages</Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="/ContactUs">Corporate Company Boookings</Link>
                          </li>
                          <li>
                            {" "}
                            <Link to="/ContactUs">All Events Bookings</Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/ContactUs">ContactUs</Link>
                      </li>
                    </ul>
                  </nav>
                  <button
                    class="th-menu-toggle d-inline-block d-lg-none"
                    onClick={toggleMenu}
                  >
                    <i class="far fa-bars"></i>
                  </button>
                </div>
                {/* <div class="col-auto d-none d-xl-block">
                  <div class="header-button">
                    <Link to="/Booking" class="th-btn style3 radius-btn2">
                      BOOK A TAXI<i class="fa-regular fa-arrow-right ms-2"></i>
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div class="logo-bg"></div>
        </div>
      </header>
    </>
  );
}
