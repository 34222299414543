import React, { useEffect } from "react";
import { Link, useLocation } from 'react-router-dom'
import Loader from "../components/Loader";
export default function AboutUs() {

  const location = useLocation()
  
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'assets/js/main.js'
    script.async = true
    document.body.appendChild(script);
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This adds smooth scrolling animation (optional)
    });
    return () => {
      document.body.removeChild(script)
    }
  }, [location.pathname])

  return (
    <>
      
      <div
        class="breadcumb-wrapper"
        data-bg-src="assets/img/breadcumb/breadcumb-bg.jpg"
        data-overlay="title"
        data-opacity="4"
      >
        <div class="container z-index-common">
          <h1 class="breadcumb-title">About Us</h1>
          <ul class="breadcumb-menu">
            <li><Link to="/">Home</Link></li>
            <li>About Us</li>
          </ul>
        </div>
      </div>
      <div class="space-top" id="about-sec">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 mb-5 mb-xl-0">
              <div class="img-box7 wow fadeInLeft">
                <div class="img1">
                  <img src="assets/img/normal/about-us-1st.jpg" alt="About" />
                </div>
                <div class="img2">
                  <img src="assets/img/normal/aboutus-img-2png.png" alt="About" />
                </div>
                <div class="journey-box">
                  <h3 class="journey-title">Started Journey</h3>
                  <span class="journey-year">2012</span>
                </div>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="ps-xxl-5 ms-xl-4 wow fadeInRight">
                <div class="title-area mb-20">
                  <span class="sub-title">ABOUT IFFTS</span>
                  <h2 class="sec-title">
                    Wherever You Need To Go We'll Take You There.
                  </h2>
                </div>
                <p class="mb-30">
                IFFTS Cabs Cars & Solutions, 
founded by <strong>Mr.ATHEEQ BA </strong>
is a trusted travel & tourism company
 based in Bangalore. Serving South India,
 we provide reliable & comfortable 
cab & car services, guided by our motto: 
Assistance & Guide to Humanity
 Inspired by Allama Iqbal's wisdom,
 we focus on building relationships & 
creating meaningful travel experiences 
for our customers.

                </p>
                <div class="journey-wrap style2">
                  <div class="journey-image">
                    <img src="assets/img/normal/map.jpg" alt="" />
                  </div>
                  <div class="checklist">
                    <ul>
                      <li>Easy & Emergency Solutions Anytime</li>
                      <li>Getting Affordable price </li>
                      <li>More Reliable & Experienced Teams</li>
                    </ul>
                  </div>
                </div>
                <Link to="/Packages">
                  <a class="th-btn"
                  ><span class="btn-text"
                  >You wanna Book Now<i
                    class="fa-regular fa-arrow-right ms-2"
                  > </i></span
                    ></a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="space">
        <div class="container">
          <div class="counter-wrap style3">
            <div class="counter-line"></div>
            <div class="row gy-40">
              <div class="col-sm-6 col-lg-3">
                <div class="counter-card style3 wow fadeInUp">
                  <div
                    class="counter-card_icon"
                    data-bg-src="assets/img/shape/counter_shape.png"
                  >
                    <img src="assets/img/icon/counter_3_1.svg" alt="icon" />
                  </div>
                  <h3 class="counter-card_number">
                    <span class="counter-number">100</span><span
                      class="counter-plus"
                    >+</span
                    >
                  </h3>
                  <p class="counter-card_text">Vehicles</p>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="counter-card style3 wow fadeInUp">
                  <div
                    class="counter-card_icon"
                    data-bg-src="assets/img/shape/counter_shape.png"
                  >
                    <img src="assets/img/icon/counter_3_2.svg" alt="icon" />
                  </div>
                  <h3 class="counter-card_number">
                    <span class="counter-number">1</span>k<span
                      class="counter-plus"
                    >+</span
                    >
                  </h3>
                  <p class="counter-card_text">People Pickup</p>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="counter-card style3 wow fadeInUp">
                  <div
                    class="counter-card_icon"
                    data-bg-src="assets/img/shape/counter_shape.png"
                  >
                    <img src="assets/img/icon/counter_3_3.svg" alt="icon" />
                  </div>
                  <h3 class="counter-card_number">
                    <span class="counter-number">300</span>k<span
                      class="counter-plus"
                    >+</span
                    >
                  </h3>
                  <p class="counter-card_text">Miles Trips Done</p>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="counter-card style3 wow fadeInUp">
                  <div
                    class="counter-card_icon"
                    data-bg-src="assets/img/shape/counter_shape.png"
                  >
                    <img src="assets/img/icon/counter_3_4.svg" alt="icon" />
                  </div>
                  <h3 class="counter-card_number">
                    <span class="counter-number">1</span>k<span
                      class="counter-plus"
                    >+</span
                    >
                  </h3>
                  <p class="counter-card_text">Satisfied Clients</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="bg-smoke space">
        <div class="container">
          <div
            class="row justify-content-lg-between justify-content-center align-items-center"
          >
            <div class="col-lg-8">
              <div class="title-area text-center text-lg-start">
                <span class="sub-title style2"
                >IFFTS Service</span
                >
                <h2 class="sec-title text-capitalize">
                  We are Best in This Services
                </h2>
              </div>
            </div>
            <div class="col-auto">
              <div class="sec-btn">
                <div class="icon-box">
                  <button
                    data-slick-prev="#serviceSlide3"
                    class="slick-arrow default"
                  >
                    <i class="far fa-arrow-left"></i>
                  </button>
                  <button
                    data-slick-next="#serviceSlide3"
                    class="slick-arrow default"
                  >
                    <i class="far fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row slider-shadow th-carousel"
            id="serviceSlide3"
            data-slide-show="3"
            data-lg-slide-show="2"
            data-md-slide-show="2"
          >
            <div class="col-md-6 col-lg-4">
              <div class="service-card style3">
                <div
                  class="service-card_overlay"
                  data-bg-src="assets/img/service/service_shape.jpg"
                ></div>
                <div class="service-card_content">
                  <div class="service-card_wrapper">
                    <div class="service-card_icon">
                      <img src="assets/img/icon/service_1_1.svg" alt="" />
                    </div>
                    <div>
                      <span class="service-card_subtitle">service - 01</span>
                      <h3 class="service-card_title">
                        <Link to="/Packages"> Airport Transfers </Link>
                      </h3>
                    </div>
                  </div>
                  <p class="service-card_text">
                    Our airport transfer services are designed to provide you with
                    a seamless and hassle-free experience when traveling to and from the airport.
                    Whether you're arriving in a new city or heading home after a trip, we understand
                    the importance of a reliable and comfortable airport transfer.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="service-card style3">
                <div
                  class="service-card_overlay"
                  data-bg-src="assets/img/service/service_shape.jpg"
                ></div>
                <div class="service-card_content">
                  <div class="service-card_wrapper">
                    <div class="service-card_icon">
                      <img src="assets/img/icon/service_1_2.svg" alt="" />
                    </div>
                    <div>
                      <span class="service-card_subtitle">service - 02</span>
                      <h3 class="service-card_title">
                        <Link to="/Packages"> City Transfers </Link>
                      </h3>
                    </div>
                  </div>
                  <p class="service-card_text">
                    Our city transfer services are designed to provide you with convenient, efficient,
                    and comfortable transportation within the city. Whether you're a local resident or a visitor
                    exploring a new destination, our city transfer services are your reliable choice for getting around.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="service-card style3">
                <div
                  class="service-card_overlay"
                  data-bg-src="assets/img/service/service_shape.jpg"
                ></div>
                <div class="service-card_content">
                  <div class="service-card_wrapper">
                    <div class="service-card_icon">
                      <img src="assets/img/icon/service_1_3.svg" alt="" />
                    </div>
                    <div>
                      <span class="service-card_subtitle">service - 03</span>
                      <h3 class="service-card_title">
                        <Link to="/Packages"> Business Transfers </Link>
                      </h3>
                    </div>
                  </div>
                  <p class="service-card_text">
                    Our business transfer services are meticulously designed to cater to the unique needs of
                    corporate travelers and professionals. We understand that your time is valuable, and efficiency,
                    reliability, and comfort are paramount when it comes to business-related transportation.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="service-card style3">
                <div
                  class="service-card_overlay"
                  data-bg-src="assets/img/service/service_shape.jpg"
                ></div>
                <div class="service-card_content">
                  <div class="service-card_wrapper">
                    <div class="service-card_icon">
                      <img src="assets/img/icon/service_1_2.svg" alt="" />
                    </div>
                    <div>
                      <span class="service-card_subtitle">service - 04</span>
                      <h3 class="service-card_title">
                        <Link to="/Packages"> Out Station </Link>
                      </h3>
                    </div>
                  </div>
                  <p class="service-card_text">
                    Our out station services are designed to provide you with convenient, efficient,
                    and comfortable transportation the cities. Whether you're a local resident or a visitor
                    exploring a new destination, our out station services are your reliable choice for getting around.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        class="position-relative overflow-hidden bg-top-center space"
        data-bg-src="assets/img/bg/booking_bg_1.jpg"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-6 wow fadeInLeft">
              <div class="title-area text-center text-xl-start">
                <h2 class="sec-title text-white">Book Your Taxi On Call</h2>
                <p class="booking-text">
                Experience hassle-free taxi booking with our user-friendly platform. 
                Your next ride is just a few clicks away.
                </p>
              </div>
              <div class="d-flex justify-content-center justify-content-xl-start">
                <div class="info-card style4">
                  <div class="info-card_icon"><i class="fas fa-phone"></i></div>
                  <div class="info-card_content">
                    <p class="info-card_text">Call for booking:</p>
                    <a href="tel:+919844834400" class="info-card_link"
                    >+91 9844834400</a
                    >
                  </div>
                </div>
              </div>

            </div>
            <div class="col-xl-6">
              <form
              >
                <div class="text-center">
                  <h3 style={{ color: "white" }}>Book Taxi In Just Few Clicks</h3>
                  <div class="row">
                    <div class="form-btn col-12">
                      <Link to="/Packages">
                        <button class="th-btn radius-btn">
                          Book Taxi Now<i class="fa-regular fa-arrow-right ms-2"></i>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="about-quote style2">
                  <div class="about-review-wrapp">
                    <span class="about-review">4.9/5</span>
                    <div class="star">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-duotone fa-star-half-stroke"></i>
                    </div>
                  </div>
                  <div>
                    <p class="text">Trust Score Rated</p>
                    <span class="rating">Rated by over 700+ customers</span>
                  </div>
                </div>
                <p class="form-messages mb-0 mt-3"></p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <section class="position-relative overflow-hidden space-bottom">
        <div class="container">
          <div class="title-area text-center">
            <span class="sub-title style2"
            >Our
              Working Process</span
            >
            <h2 class="sec-title">Our Basic Work Process</h2>
          </div>
          <div class="process-box-wrapper style2">
            <div class="row gy-30 justify-content-center">
              <div class="col-md-6 col-lg-4">
                <div
                  class="process-item"
                  data-bg-src="assets/img/shape/process_shape.png"
                >
                  <div class="process-item_icon">
                    <img src="assets/img/icon/process_1_1.svg" alt="" />
                  </div>
                  <h3 class="process-item_title">Searching Taxi</h3>
                  <p class="process-item_text">
                    Taxi booking process is the process of requesting a taxi
                    service.
                  </p>
                  <span class="process-item_num">01</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div
                  class="process-item"
                  data-bg-src="assets/img/shape/process_shape.png"
                >
                  <div class="process-item_icon">
                    <img src="assets/img/icon/process_1_2.svg" alt="" />
                  </div>
                  <h3 class="process-item_title">Book A Taxi</h3>
                  <p class="process-item_text">
                    The user contacts the provider through a phone call, or from
                    website.
                  </p>
                  <span class="process-item_num">02</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div
                  class="process-item"
                  data-bg-src="assets/img/shape/process_shape.png"
                >
                  <div class="process-item_icon">
                    <img src="assets/img/icon/process_1_3.svg" alt="" />
                  </div>
                  <h3 class="process-item_title">Arrive safely</h3>
                  <p class="process-item_text">
                    The user provides their pickup location, and preferred time of
                    arrival.
                  </p>
                  <span class="process-item_num">03</span>
                </div>
              </div>
              <span class="process-line"
              ><img src="assets/img/shape/process_line_3.png" alt="line"
                /></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
