import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Home from "./pages/Home";
import Header from "./components/header/Header";
import Footer from "./components/Footer/Footer";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Homepage from "./pages/Homepage";
import Service from "./pages/Service";
import Packages from "./pages/Packages";
import Admin from "./pages/Admin";
import AdminDashboard from "./pages/AdminDashboard";
import Booking from "./pages/Booking";
import Error from "./pages/Error";
import PackageService from "./pages/Packages/PackageService"
import Loader from "./components/Loader";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/AboutUs" element={<AboutUs/>}/>
        <Route path="/ContactUs" element={<ContactUs/>}/>
        <Route path="/Service" element={<Service/>}/>
        <Route path="/Packages" element={<Packages/>}/>
        <Route path="/Admin" element={<Admin/>}/>
        <Route path="/AdminDashboard" element={<AdminDashboard/>}/>
        <Route path="/Booking" element={<Booking/>}/>
        <Route path="/Package" element={<PackageService />}/>
        <Route path="*" element={<Error/>}/>
      </Routes>
      <Footer />

    </BrowserRouter>

  );
}

export default App;
