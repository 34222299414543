import React, { useEffect } from "react";
import { Link, useLocation } from 'react-router-dom'
export default function Packages() {

  const location = useLocation()
  
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'assets/js/main.js'
    script.async = true
    document.body.appendChild(script);
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This adds smooth scrolling animation (optional)
    });
    return () => {
      document.body.removeChild(script)
    }
  }, [location.pathname])

  return (
    <>
<section class="bg-white space">
      <div class="container">
        <div class="title-area text-center">
          <h4 class="sec-title" style={{paddingTop:"70px"}}>Our Packages</h4>
        </div>
        <div class="row gy-40">
          <div class="col-md-6 col-lg-4">
            <div
              class="team-box wow fadeInLeft"
              style={{visibility: "visible", animationName: "fadeInLeft"}}
            >
               <Link to="/Package" state={{ type: 1 }}>
              <div class="team-img">
                <img
                  src="assets/img/innova-crysta--with airplane.jpg"
                  alt="Team"
                />
                <div class="team-content">
                  <h3 class="team-title" style={{opacity:"100%"}}>
                   Airport Packages
                  </h3>
                  <span class="team-desig" style={{opacity:"100%"}}>Click To Book</span>
                </div>
              </div>
              </Link>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div
              class="team-box wow fadeInUp"
              style={{visibility: "visible", animationName: "fadeInUp"}}
            >
               <Link to="/Package" state={{ type: 2 }}>
              <div class="team-img">
                <img
                  src="assets/img/airport-to-city.jpg"
                  alt="Team"
                />
                <div class="team-content">
                  <h3 class="team-title" style={{opacity:"100%"}}>Local City Packages
                  </h3>
                  <span class="team-desig" style={{opacity:"100%"}}>Click To Book</span>
                </div>
              </div>
              </Link>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <div
              class="team-box wow fadeInLeft"
              style={{visibility: "visible", animationName:"fadeInLeft"}}
            >
               <Link to="/Service">
              <div class="team-img">
                <img
                  src="assets/img/outstation-cabs.jpg"
                  alt="Team"
                />
                <div class="team-content">
                  <h3 class="team-title" style={{opacity:"100%"}}>
                      Outstation Packages
                  </h3>
                  <span class="team-desig" style={{opacity:"100%"}}>Click To Book</span>
                </div>
              </div>
              </Link>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    </>
     );
    }