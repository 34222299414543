import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Loader from "../components/Loader";

export default function Homepage() {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/main.js";
    script.async = true;
    document.body.appendChild(script);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This adds smooth scrolling animation (optional)
    });

    return () => {
      document.body.removeChild(script);
    };
  }, [location.pathname]);
  return (
    <>
      {isLoading && <Loader />}
      <div class="th-hero-wrapper hero-9">
        <div
          class="hero-slider-9 th-carousel"
          id="heroSlide9"
          data-slide-show="1"
          data-md-slide-show="1"
          data-fade="true"
        >
          <div class="th-hero-slide">
            <div class="th-hero-bg">
              <div
                class="hero-img"
                data-ani="slideinright"
                data-ani-delay="0.9s"
              >
                <img src="assets/img/hero/InnovaSlide.jpg" alt="" />
              </div>
            </div>
            <div class="container">
              <div class="hero-style9">
                <h1
                  class="hero-title"
                  data-ani="slideinleft"
                  data-ani-delay="0.4s"
                >
                  A different kind of taxi
                  <span class="highlight-title">IFFTS</span>
                </h1>
                <p
                  class="hero-text"
                  data-ani="slideinleft"
                  data-ani-delay="0.6s"
                >
                  We take immense pride in being your trusted partner for taxi
                  services across India. At IFFTS, we believe that your journey
                  should be more than just a ride; it should be an experience to
                  remember. Our commitment to humanity and inclusivity drives
                  our service.
                </p>
                <div
                  class="btn-group"
                  data-ani="slideinup"
                  data-ani-delay="0.7s"
                >
                  <Link to="/Packages">
                    <a class="th-btn style3 radius-btn2">
                      <span class="btn-text">
                        Book Now
                        <i class="fa-regular fa-arrow-right ms-2"></i>
                      </span>
                    </a>
                  </Link>
                  <a href="tel:+919844834400" class="th-btn style2 radius-btn2">
                    <span class="btn-text">
                      Call Now<i class="fa-regular fa-arrow-right ms-2"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="th-hero-slide">
            <div class="th-hero-bg">
              <div
                class="hero-img"
                data-ani="slideinright"
                data-ani-delay="0.9s"
              >
                <img src="assets/img/hero/InnovaSlide.jpg" alt="" />
              </div>
            </div>
            <div class="container">
              <div class="hero-style9">
                <h1
                  class="hero-title"
                  data-ani="slideinleft"
                  data-ani-delay="0.4s"
                >
                  Need a Taxi ? Call us
                  <span class="highlight-title">Anytime</span>
                </h1>
                <p
                  class="hero-text"
                  data-ani="slideinleft"
                  data-ani-delay="0.6s"
                >
                  Need a taxi at any hour of the day or night? We've got you
                  covered. Our taxis are ready to take you to your destination
                  24/7, ensuring that you reach your desired location on time
                  and hassle-free. Our services are open to all, and we are
                  proud to serve a diverse community of travelers.
                </p>
                <div
                  class="btn-group"
                  data-ani="slideinup"
                  data-ani-delay="0.7s"
                >
                  <Link to="/Packages">
                    <a class="th-btn style3 radius-btn2">
                      <span class="btn-text">
                        Book Now
                        <i class="fa-regular fa-arrow-right ms-2"></i>
                      </span>
                    </a>
                  </Link>
                  <a href="tel:+919844834400" class="th-btn style2 radius-btn2">
                    <span class="btn-text">
                      Call Now<i class="fa-regular fa-arrow-right ms-2"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="th-hero-slide">
            <div class="th-hero-bg">
              <div
                class="hero-img"
                data-ani="slideinright"
                data-ani-delay="0.9s"
              >
                <img src="assets/img/hero/InnovaSlide.jpg" alt="" />
              </div>
            </div>
            <div class="container">
              <div class="hero-style9">
                <h1
                  class="hero-title"
                  data-ani="slideinleft"
                  data-ani-delay="0.4s"
                >
                  Best Way To Get Around
                  <span class="highlight-title">Bengaluru</span>
                </h1>
                <p
                  class="hero-text"
                  data-ani="slideinleft"
                  data-ani-delay="0.6s"
                >
                  Whether you need a quick ride within the city, an airport
                  transfer, or even an outstation trip, Bengaluru's taxi
                  services have you covered. Choose from a variety of vehicle
                  types to suit your needs and preferences.
                </p>
                <div
                  class="btn-group"
                  data-ani="slideinup"
                  data-ani-delay="0.7s"
                >
                  <Link to="/Packages">
                    <a class="th-btn style3 radius-btn2">
                      <span class="btn-text">
                        Book Now
                        <i class="fa-regular fa-arrow-right ms-2"></i>
                      </span>
                    </a>
                  </Link>
                  <a href="tel:+919844834400" class="th-btn style2 radius-btn2">
                    <span class="btn-text">
                      Call Now<i class="fa-regular fa-arrow-right ms-2"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="icon-box">
          <button data-slick-prev="#heroSlide9" class="slick-arrow default">
            <i class="fa-regular fa-arrow-left"></i>
          </button>
          <button data-slick-next="#heroSlide9" class="slick-arrow default">
            <i class="fa-regular fa-arrow-right"></i>
          </button>
        </div>
        <div class="hero-overlay">
          <img
            style={{ width: "1800px" }}
            src="assets/img/bg/hero_overlay_9.png"
            alt=""
          />
        </div>
      </div>
      <section class="space" id="about-sec">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-6 mb-40 mb-xl-0">
              <div class="img-box4 style3 pe-xl-4 me-xl-4 wow fadeInLeft">
                <div class="img1">
                  <img src="assets/img/normal/about_3.png" alt="About" />
                </div>
                <div class="img2">
                  <img src="assets/img/normal/about_3_1.jpg" alt="About" />
                </div>
                <div class="th-experience">
                  <h3 class="experience-year">
                    <span class="counter-number">10</span>+
                  </h3>
                  <p class="experience-text">Years Experience</p>
                </div>
              </div>
            </div>
            <div class="col-xl-6 wow fadeInRight">
              <div class="title-area mb-35 text-center text-md-start">
                <span class="sub-title style2">ABOUT OUR COMPANY</span>
                <h2 class="sec-title text-capitalize">
                  IFFTS cabs & car solutions Is Most Trusted cabs in Bengaluru
                </h2>
              </div>
              <p class="mt-n2 mb-30 text-center text-md-start">
                "Step into comfort and let us navigate the roads for you, Ride
                with us and experience the beauty of the journey, one mile at a
                time."
              </p>
              <div class="text-center text-md-start">
                <Link to="/AboutUs">
                  <a class="th-btn radius-btn2">
                    Discover More<i class="fa-regular fa-arrow-right ms-2"></i>
                  </a>{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          class="shape-mockup d-none d-xl-block"
          data-top="0%"
          data-left="0%"
        >
          <img src="assets/img/shape/about_shape.png" alt="shapes" />
        </div>
      </section>
      <section
        class="service-area space"
        data-bg-src="assets/img/bg/Packages_bg_3.jpg"
      >
        <div class="container">
          <div class="title-area text-center">
            <h2 class="sec-title">Cabs Available</h2>
          </div>
          <div
            class="row slider-shadow th-carousel"
            id="slideListTab2"
            data-asnavfor="#slideListBox"
            data-slide-show="3"
            data-lg-slide-show="3"
            data-md-slide-show="3"
            data-sm-slide-show="3"
            data-xs-slide-show="3"
            data-arrows="true"
            data-center-mode="true"
            data-xl-center-mode="true"
            data-ml-center-mode="true"
            data-lg-center-mode="true"
            data-md-center-mode="true"
            data-sm-center-mode="true"
            data-xs-center-mode="true"
          >
            <div class="col-md-6 col-lg-4">
              <div class="service-list">
                <div class="service-list_icon">
                  <img
                    src="assets/img/cars/Nissan-Micra.png"
                    alt="service Icon"
                  />
                </div>
                <div class="service-list_content">
                  <span class="service-list_text">Available Now</span>
                  <h3 class="service-list_title">HatchBack</h3>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="service-list">
                <div class="service-list_icon">
                  <img
                    src="assets/img/cars/maruti-ciaz.png"
                    alt="service Icon"
                  />
                </div>
                <div class="service-list_content">
                  <span class="service-list_text">Available Now</span>
                  <h3 class="service-list_title">Sedan</h3>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="service-list">
                <div class="service-list_icon">
                  <img
                    src="assets/img/cars/toyota-hycross.png"
                    alt="service Icon"
                  />
                </div>
                <div class="service-list_content">
                  <span class="service-list_text">Available Now</span>
                  <h3 class="service-list_title">SUV</h3>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="service-list">
                <div class="service-list_icon">
                  <img
                    src="assets/img/cars/Toyota-Hiace.png"
                    alt="service Icon"
                  />
                </div>
                <div class="service-list_content">
                  <span class="service-list_text">Available Now</span>
                  <h3 class="service-list_title">Mini Bus</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="service-list-area wow fadeInUp">
            <div
              class="th-carousel"
              id="slideListBox"
              data-fade="true"
              data-asnavfor="#slideListTab2"
            >
              <div class="service-taxi">
                <div class="service-taxi-wrapper">
                  <div class="service-taxi-image">
                    <img src="assets/img/cars/Nissan-Micra.png" alt="taxi" />
                  </div>
                  <div class="service-taxi-content">
                    <div class="taxi-about">
                      <h4 class="taxi-about_title">HatchBack</h4>
                      <p class="taxi-about_text">
                        Click on Discover more and select your car.
                      </p>
                    </div>
                    <div class="taxi-feature-wrapper">
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_1.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Car Doors:</h3>
                        <span class="taxi-feature_info">4</span>
                      </div>
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_3.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Luggage Carry:</h3>
                        <span class="taxi-feature_info">Yes</span>
                      </div>
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_2.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Passengers:</h3>
                        <span class="taxi-feature_info">4+1</span>
                      </div>
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_5.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Air Condition:</h3>
                        <span class="taxi-feature_info">Yes</span>
                      </div>
                    </div>
                    <Link to="/Packages">
                      <a class="th-btn radius-btn2">
                        Discover More
                        <i class="fa-regular fa-arrow-right ms-2"></i>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="service-taxi">
                <div class="service-taxi-wrapper">
                  <div class="service-taxi-image">
                    <img src="assets/img/cars/maruti-ciaz.png" alt="taxi" />
                  </div>
                  <div class="service-taxi-content">
                    <div class="taxi-about">
                      <h4 class="taxi-about_title">Sedan</h4>
                      <p class="taxi-about_text">
                        Click on Discover more and select your car.
                      </p>
                    </div>
                    <div class="taxi-feature-wrapper">
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_1.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Car Doors:</h3>
                        <span class="taxi-feature_info">4</span>
                      </div>
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_3.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Luggage Carry:</h3>
                        <span class="taxi-feature_info">yes</span>
                      </div>
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_2.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Passengers:</h3>
                        <span class="taxi-feature_info">4+1</span>
                      </div>

                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_5.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Air Condition:</h3>
                        <span class="taxi-feature_info">Yes</span>
                      </div>
                    </div>
                    <Link to="/Packages">
                      <a class="th-btn radius-btn2">
                        Discover More
                        <i class="fa-regular fa-arrow-right ms-2"></i>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="service-taxi">
                <div class="service-taxi-wrapper">
                  <div class="service-taxi-image">
                    <img src="assets/img/cars/toyota-hycross.png" alt="taxi" />
                  </div>
                  <div class="service-taxi-content">
                    <div class="taxi-about">
                      <h4 class="taxi-about_title">SUV</h4>
                      <p class="taxi-about_text">
                        Click on Discover more and select your car.
                      </p>
                    </div>
                    <div class="taxi-feature-wrapper">
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_1.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Car Doors:</h3>
                        <span class="taxi-feature_info">4</span>
                      </div>
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_3.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Luggage Carry:</h3>
                        <span class="taxi-feature_info">Yes</span>
                      </div>
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_2.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Passengers:</h3>
                        <span class="taxi-feature_info">(6+1) & (7+1)</span>
                      </div>
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_5.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Air Condition:</h3>
                        <span class="taxi-feature_info">Yes</span>
                      </div>
                    </div>
                    <Link to="/Packages">
                      <a class="th-btn radius-btn2">
                        Discover More
                        <i class="fa-regular fa-arrow-right ms-2"></i>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="service-taxi">
                <div class="service-taxi-wrapper">
                  <div class="service-taxi-image">
                    <img src="assets/img/cars/Toyota-Hiace.png" alt="taxi" />
                  </div>
                  <div class="service-taxi-content">
                    <div class="taxi-about">
                      <h4 class="taxi-about_title">Mini Bus</h4>
                      <p class="taxi-about_text">
                        Click on Discover more and select your car.
                      </p>
                    </div>
                    <div class="taxi-feature-wrapper">
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_1.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Car Doors:</h3>
                        <span class="taxi-feature_info">4</span>
                      </div>
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_3.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Luggage Carry:</h3>
                        <span class="taxi-feature_info">Yes</span>
                      </div>
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_2.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Passengers:</h3>
                        <span class="taxi-feature_info">12,21,23,38</span>
                      </div>
                      <div class="taxi-feature">
                        <div class="taxi-feature_icon">
                          <img src="assets/img/icon/taxi_f_1_5.svg" alt="png" />
                        </div>
                        <h3 class="taxi-feature_title">Air Condition:</h3>
                        <span class="taxi-feature_info">Yes</span>
                      </div>
                    </div>
                    <Link to="/Packages">
                      <a class="th-btn radius-btn2">
                        Discover More
                        <i class="fa-regular fa-arrow-right ms-2"></i>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        class="testi-sec3 overflow-hidden space"
        data-bg-src="assets/img/bg/testimonial_bg_3.jpg"
      >
        <div class="container">
          <div class="row  justify-content-center align-items-center">
            <span class="brand-title">
              <span class="counter-title">Our Testimonials</span>
            </span>
          </div>
          <div class="row">
            <div class="col-12">
              <div
                class="testi-box-area"
                data-bg-src="assets/img/bg/testi_bg_shape.jpg"
              >
                <div class="testi-box-slide">
                  <div class="title-area text-center text-xl-start mb-30">
                    <h2 class="sec-title">Our Happy Client’s Review</h2>
                  </div>
                  <div
                    class="slider-shadow th-carousel"
                    id="testiSlide1"
                    data-slide-show="1"
                    data-fade="true"
                  >
                    <div>
                      <div class="testi-box">
                        <div class="testi-star">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </div>
                        <p class="testi-box_text">
                          “They really provide an awesome and proactive service.
                          The driver insisted us to visit few extra locations
                          throughout the journey, which was a different
                          experience. They do not just to business they manage
                          guests as if they are family. I am really happy with
                          the service provided. Thanks a lot.😄”.
                        </p>
                        <div class="testi-box_wrapper">
                          <div class="testi-box_profile">
                            <div class="testi-box_avater">
                              <i class="fas fa-user-alt"></i>
                            </div>
                            <div class="media-body">
                              <h3 class="testi-box_name h6">
                                Bidisha Bhattacharya
                              </h3>
                              <span class="testi-box_desig">Customer</span>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="text-center text-md-start">
                          <a
                            class="th-btn radius-btn2"
                            target="_blank"
                            href="https://www.google.com/search?q=iffts&rlz=1C1ONGR_enIN1045IN1045&oq=iffts+&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQABiABDIGCAIQRRhBMgYIAxBFGEEyBggEEEUYQTIGCAUQRRg80gEIMzA0MWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x3bae17dad46234a1:0x410754cef2f7dfba,1,,,,"
                          >
                            Checkout Google Review
                            <i class="fa-regular fa-arrow-right ms-2"></i>
                          </a>{" "}
                        </div>
                        <div class="testi-box_quote">
                          <img src="assets/img/icon/quote_2.svg" alt="quote" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="testi-box">
                        <div class="testi-star">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </div>
                        <p class="testi-box_text">
                          Believe in the best Cabs Service.. Quality Cabs and
                          Good Drivers.. All's Good Management..
                        </p>
                        <div class="testi-box_wrapper">
                          <div class="testi-box_profile">
                            <div class="testi-box_avater">
                              <i class="fas fa-user-alt"></i>
                            </div>
                            <div class="media-body">
                              <h3 class="testi-box_name h6">Samiulla</h3>
                              <span class="testi-box_desig">Customer</span>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="text-center text-md-start">
                          <a
                            class="th-btn radius-btn2"
                            target="_blank"
                            href="https://www.google.com/search?q=iffts&rlz=1C1ONGR_enIN1045IN1045&oq=iffts+&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQABiABDIGCAIQRRhBMgYIAxBFGEEyBggEEEUYQTIGCAUQRRg80gEIMzA0MWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x3bae17dad46234a1:0x410754cef2f7dfba,1,,,,"
                          >
                            Checkout Google Review
                            <i class="fa-regular fa-arrow-right ms-2"></i>
                          </a>{" "}
                        </div>
                        <div class="testi-box_quote">
                          <img src="assets/img/icon/quote_2.svg" alt="quote" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="testi-box">
                        <div class="testi-star">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </div>
                        <p class="testi-box_text">
                          A very pleasant experience during our 4-day trip.
                          Driver (Atheeq) was very friendly, respectful and
                          experienced.
                        </p>
                        <div class="testi-box_wrapper">
                          <div class="testi-box_profile">
                            <div class="testi-box_avater">
                              <i class="fas fa-user-alt"></i>
                            </div>
                            <div class="media-body">
                              <h3 class="testi-box_name h6">Ram Narayanan</h3>
                              <span class="testi-box_desig">Customer</span>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="text-center text-md-start">
                          <a
                            class="th-btn radius-btn2"
                            target="_blank"
                            href="https://www.google.com/search?q=iffts&rlz=1C1ONGR_enIN1045IN1045&oq=iffts+&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQABiABDIGCAIQRRhBMgYIAxBFGEEyBggEEEUYQTIGCAUQRRg80gEIMzA0MWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x3bae17dad46234a1:0x410754cef2f7dfba,1,,,,"
                          >
                            Checkout Google Review
                            <i class="fa-regular fa-arrow-right ms-2"></i>
                          </a>{" "}
                        </div>
                        <div class="testi-box_quote">
                          <img src="assets/img/icon/quote_2.svg" alt="quote" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="testi-box">
                        <div class="testi-star">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </div>
                        <p class="testi-box_text">
                          Cab service is awesome, Reached early for pickup and
                          driving was fantastic with proper traffic discipline.
                        </p>
                        <div class="testi-box_wrapper">
                          <div class="testi-box_profile">
                            <div class="testi-box_avater">
                              <i class="fas fa-user-alt"></i>
                            </div>
                            <div class="media-body">
                              <h3 class="testi-box_name h6">vasu devan</h3>
                              <span class="testi-box_desig">Customer</span>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="text-center text-md-start">
                          <a
                            class="th-btn radius-btn2"
                            target="_blank"
                            href="https://www.google.com/search?q=iffts&rlz=1C1ONGR_enIN1045IN1045&oq=iffts+&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQABiABDIGCAIQRRhBMgYIAxBFGEEyBggEEEUYQTIGCAUQRRg80gEIMzA0MWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x3bae17dad46234a1:0x410754cef2f7dfba,1,,,,"
                          >
                            Checkout Google Review
                            <i class="fa-regular fa-arrow-right ms-2"></i>
                          </a>{" "}
                        </div>
                        <div class="testi-box_quote">
                          <img src="assets/img/icon/quote_2.svg" alt="quote" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="testi-box">
                        <div class="testi-star">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </div>
                        <p class="testi-box_text">
                          IFFTS Cabs, Cars & Solutions is the go-to choice for
                          car rentals in South India. The vehicles are
                          well-maintained, and the drivers are professional and
                          punctual. I've used their services multiple times, and
                          each experience has been exceptional. Whether it's a
                          quick ride within the city or a long-distance journey,
                          IFFTS Cabs always delivers. Their pricing is
                          competitive, and the booking process is effortless. If
                          you're in Bangalore or anywhere in South India, I
                          highly recommend IFFTS Cabs, Cars & Solutions for a
                          hassle-free and enjoyable travel experience.
                        </p>
                        <div class="testi-box_wrapper">
                          <div class="testi-box_profile">
                            <div class="testi-box_avater">
                              <i class="fas fa-user-alt"></i>
                            </div>
                            <div class="media-body">
                              <h3 class="testi-box_name h6">Mohammed Naveed</h3>
                              <span class="testi-box_desig">Customer</span>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div class="text-center text-md-start">
                          <a
                            class="th-btn radius-btn2"
                            target="_blank"
                            href="https://www.google.com/search?q=iffts&rlz=1C1ONGR_enIN1045IN1045&oq=iffts+&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQABiABDIGCAIQRRhBMgYIAxBFGEEyBggEEEUYQTIGCAUQRRg80gEIMzA0MWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x3bae17dad46234a1:0x410754cef2f7dfba,1,,,,"
                          >
                            Checkout Google Review
                            <i class="fa-regular fa-arrow-right ms-2"></i>
                          </a>{" "}
                        </div>
                        <div class="testi-box_quote">
                          <img src="assets/img/icon/quote_2.svg" alt="quote" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="testi-box-img">
                  <img
                    src="assets/img/testimonial/testi_img.png"
                    alt="Testmonial"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Terms"
        class="booking-area2 position-relative bg-title"
        data-bg-src="assets/img/bg/booking_bg_3.png"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12 ps-xl-12 wow ">
              <div class="widget widget_nav_menu footer-widget ">
                <h1
                  class="widget_title"
                  style={{
                    color: "#4744fa",
                    fontSize: "40px",
                    paddingTop: "20px",
                  }}
                >
                  Terms & Conditions
                </h1>
                <div class="menu-all-pages-container">
                  <ul class="menu">
                    {/* <li>
                      <a
                      >Minimum of 300 kilometers counted per calendar day.</a>
                    </li>
                    <li>
                      <a

                      >Tariff per kilometer varies based on the chosen vehicle type.</a>
                    </li>
                    <li>
                      <a
                      >Smoking and alcohol consumption are strictly prohibited inside the vehicle.</a
                      >
                    </li>
                    <li>
                      <a
                      >In hill station areas, air conditioning must be turned off</a
                      >
                    </li>
                    <li>
                      <a
                      >Driver's working hours are from 6 AM to 10 PM.</a
                      >
                    </li>
                    <li>
                      <a
                      >Additional fees apply for driving after 10 PM.</a
                      >
                    </li>
                    <li>
                      <a
                      >The calculation of kilometers begins and ends at our office.</a
                      >
                    </li>
                    <li>
                      <a
                      >For one-way bookings, the kilometers traveled are billed as a round trip.</a
                      >
                    </li> */}
                    <li>
                      <a>
                        Additional charges apply for intercity (other states)
                        permit, toll, and parking.
                      </a>
                    </li>
                    <li>
                      <a>The luggage allowance for all vehicles is limited..</a>
                    </li>
                    <li>
                      <a>Goods luggage is not permitted.</a>
                    </li>
                    <li>
                      <a>
                        Extra charges apply for any instances of soiling or
                        dirtying.
                      </a>
                    </li>
                    <li>
                      <a>Rental services are available for city locals.</a>
                    </li>
                    <li>
                      <a>
                        Seating capacity for all vehicles is strictly adhered
                        to; no extra passengers are allowed.
                      </a>
                    </li>
                    <li>
                      <a>Seat counts for every 5 to 7 years age</a>
                    </li>
                    <li>
                      <a>
                        {" "}
                        A service charge of Rs. 300 to Rs. 500 is added for
                        every outstation trip.
                      </a>
                    </li>
                    <li>
                      <a> Additional charges apply for a GST bill.</a>
                    </li>
                    <li>
                      <a>
                        {" "}
                        Booking confirmation requires a compulsory advance
                        payment.
                      </a>
                    </li>
                    <li>
                      <a>
                        {" "}
                        For Every Bookings (Booking Hub Charges) is Rs.100 to Rs.200
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="booking-image">
          <img src="assets/img/bg/booking_img.jpg" alt="" />
        </div>
        <div class="booking-content">
          <h3 class="booking-text">Conditions Applied</h3>
        </div> */}
        <div class="shape-mockup">
          <img src="assets/img/bg/booking_shape.png" alt="shapes" />
        </div>
        <div
          class="shape-mockup d-none d-xl-block"
          data-bottom="0%"
          data-right="0%"
        >
          <img
            src="assets/img/cars/suzuki-swift-dezire-WithLG.png"
            alt="hycross"
          />
        </div>
      </div>
      <br />
      {/* <div className={`flash-message ${true ? "show" : "hide"}`}>
        <div>
          <div class="row gy-40">
            <div class="col-md-12 col-lg-12">
              <div
                class="team-box wow fadeInLeft"
                style={{ visibility: "visible" }}
              >
                <Link to="/Package" state={{ type: 1 }}>
                  <div class="team-img">
                    <img
                      src="assets/img/outstation-cabs.jpg"
                      alt="Team"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <button className="close-btn">&times;</button>
        </div>
      </div> */}
    </>
  );
}
